.CartDataProducts {
  width: 65%;
  height: 100%;
  padding: 0 20px;
  border-right: 2px solid #ececec;
  box-sizing: border-box;
}
.CartDataProducts > img {
  /*width: 100%;*/
  margin: 0 auto 15px;
  display: block;
}
@media (max-width: 340px) {
  .CartDataProducts > img {
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .CartDataProducts {
    width: 100%;
    border-right: none;
  }
}
@media (max-width: 560px) {
  .CartDataProducts {
    padding: 0;
  }
}
.CartDataProducts-header {
  display: grid;
  grid-template-columns: 15% 30% 15% 15% 25%;
  border-bottom: 3px solid #ececec;
}
@media (max-width: 560px) {
  .CartDataProducts-header {
    grid-template-columns: 25% 55% 20%;
  }
}
.CartDataProducts-header > span {
  font-family: "Montserrat_Bold";
  font-size: 0.9rem;
  color: #777;
  padding: 0.5em;
  justify-self: center;
}
.CartDataProducts-product {
  display: grid;
  grid-template-columns: 10% 35% 15% 15% 25%;
  border-bottom: 1px solid #ececec;
}
@media (max-width: 560px) {
  .CartDataProducts-product {
    grid-template-columns: 25% 55% 20%;
  }
}
.CartDataProducts-product-delete {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
}
.CartDataProducts-product-delete > button {
  display: block;
  width: 24px;
  height: 24px;
  font-size: 1rem !important;
  line-height: 1.3 !important;
  border-radius: 100%;
  color: #ccc;
  font-weight: bold;
  text-align: center;
  border: 2px solid #ccc;
  cursor: pointer;
  background-color: #fff;
}
.CartDataProducts-product-delete > button:hover {
  color: #000;
  border-color: #000;
}
.CartDataProducts-product-icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: max-content;
  margin-bottom: 10px;
}
.CartDataProducts-product-icons > img {
  width: 60px;
  height: 60px;
}
.CartDataProducts-product-option {
  align-self: center;
  padding: 0.5em;
}
.CartDataProducts-product-option-name {
  font-family: "Montserrat_Bold";
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 5px;
}
.CartDataProducts-product-option-desc {
  font-family: "Montserrat_400";
  font-size: 0.9rem;
  color: #666;
}
.CartDataProducts-product-price,
.CartDataProducts-product-amount {
  font-family: "Montserrat_400";
  font-size: 0.9rem;
  color: #666;
  align-self: center;
  justify-self: center;
}
.CartDataProducts-totalSum {
  min-height: 40px;
  height: max-content;
  display: grid;
  grid-template-columns: 10% 35% 15% 15% 25%;
  border-bottom: 1px solid #ececec;
  /*align-items: center;
    justify-content: flex-end;*/
}
@media (max-width: 560px) {
  .CartDataProducts-totalSum {
    grid-template-columns: 10% 55% 35%;
  }
}
.CartDataProducts-totalSum > p {
  align-self: center;
  justify-self: center;
  color: #111;
  font-size: 0.9rem;
  font-family: "Montserrat_Bold";
}
.CartDataWrapper {
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}
@media (max-width: 1000px) {
  .CartDataWrapper {
    width: 100%;
    border-right: none;
  }
}
