.SizeOption {
  border-bottom: 1px solid #EAEAEA;
}
.SizeOption-item {
  width: 100%;
  border: 1.5px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  background: #FFFFFF;
  position: relative;
}
.SizeOption-item.selected {
  border: 1.5px solid #774D2E;
  pointer-events: none;
}
.SizeOption-item.selected:after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("../../assets/images/check-selected-icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.SizeOption-item > p {
  font-family: "Montserrat_300";
  font-size: 0.75rem;
}
.SizeOption-item > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.SizeOption-item-name {
  font-family: "Montserrat_400";
  font-size: 1.125rem;
  color: #0E0E0E;
}
.SizeOption-item-price {
  font-family: "Spartan_Bold";
  font-size: 1rem;
  color: #0E0E0E;
}
.SizeOption > p {
  font-family: "Montserrat_300";
  font-size: 1.7rem;
  line-height: 38px;
  color: #0E0E0E;
  margin-bottom: 1rem;
}
