.CartDataSubtotalFormikForm {
  padding: 15px 15px 0 15px;
  background-color: #00000008;
}
.CartDataSubtotalFormikForm .TextField-input {
  margin-bottom: 0;
}
.CartDataSubtotalFormikForm-submit {
  width: 100%;
  margin: 20px 0;
  outline: none;
  color: #fff;
  background-color: #a27557;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  cursor: pointer;
  font-weight: bolder;
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  vertical-align: middle;
  border-radius: 0;
  text-shadow: none;
  line-height: 2.4em;
  min-height: 2.5em;
  padding: 0 1.2em;
  max-width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.CartDataSubtotalFormikForm-submit:hover {
  background-color: #774D2E;
}
.CartDataSubtotalFormikForm-submit:active {
  position: relative;
  top: 2px;
}
