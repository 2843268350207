.Selection {
  position: relative;
}
.Selection * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.Selection-header {
  font-weight: bold;
  display: block;
  font-size: 0.8em;
  margin-bottom: 0.4em;
  color: #222;
  font-family: "Montserrat_Bold";
}
.Selection .obligatory-field:after {
  content: " * ";
}
.Selection .hidden-border-bottom {
  border-bottom: 1px solid #fff;
}
.Selection-select-value {
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 0 0.75em;
  height: 2.5em;
  font-family: "Montserrat_400", sans-serif;
  font-size: 0.97rem;
  max-width: 100%;
  width: 100%;
  background-color: #fff;
  color: #333;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.Selection-wrap {
  position: absolute;
  right: 0;
  top: 0;
  height: 2.5em;
  width: 30px;
  color: #333;
  background: url(../../assets/images/arrow.svg) no-repeat center;
  font-family: "Montserrat_400", sans-serif;
  font-size: 0.97rem;
  opacity: 0.6;
}
.Selection-box {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  z-index: 1051;
  border-radius: 0;
  border-top: none;
  position: absolute;
  width: 100%;
}
.Selection-box .selected {
  background-color: #ddd;
}
.Selection-box-search {
  padding: 4px;
}
.Selection-box-search-field {
  width: 100%;
  height: 2.5em;
  padding: 4px;
  font-size: 0.97em;
  vertical-align: middle;
  background-color: #fff;
  color: #333;
  border-radius: 0;
  border: 1px solid #aaa;
  font-family: "Montserrat_400", sans-serif;
  font-size: 0.97rem;
  box-shadow: inset 0 -1.8em 1em 0 rgba(0, 0, 0, 0);
  outline: none;
}
.Selection-box-search-field:focus {
  box-shadow: 0 0 5px #ccc;
  opacity: 1 !important;
  outline: 0;
  color: #333;
  background-color: #fff;
}
.Selection-box-options-list {
  list-style: none;
  max-height: 200px;
  overflow-y: auto;
}
.Selection-box-option {
  cursor: pointer;
  padding: 6px;
  display: list-item;
  margin-bottom: 0.6em;
  font-family: "Montserrat_400", sans-serif;
  font-size: 0.97rem;
  line-height: 1.6;
  color: #777;
  outline: none;
}
.Selection-box-option:hover {
  background-color: #0073aa;
  color: #fff;
}
