.HotTubPositionsMenu {
  display: flex;
  flex-direction: column;
}
.HotTubPositionsMenu > span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
  margin-bottom: 15px;
  position: relative;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.32);
  cursor: pointer;
}
@media screen and (max-device-width: 770px) {
  .HotTubPositionsMenu > span {
    width: 40px;
    height: 40px;
  }
}
.HotTubPositionsMenu > span.selected {
  pointer-events: none;
}
.HotTubPositionsMenu > span.selected:before {
  content: "";
  display: block;
  position: absolute;
  width: 54px;
  height: 54px;
  border: 2.5px solid #774D2E;
  border-radius: 50%;
}
@media screen and (max-device-width: 770px) {
  .HotTubPositionsMenu > span.selected:before {
    width: 44px;
    height: 44px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.HotTubPositionsMenu > span:after {
  content: "";
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (max-device-width: 770px) {
  .HotTubPositionsMenu > span:after {
    width: 30px;
    height: 30px;
  }
}
.HotTubPositionsMenu-middleTopWater:after {
  background-image: url("../../assets/images/positionIcons/hot-tub-position-top-middle-with-water.svg");
  top: 0;
  left: 7px;
}
.HotTubPositionsMenu-middleTopWater.jadeMiddleTopWater:after {
  background-image: url("../../assets/images/positionIcons/jadeIcons/jade-1.png");
  top: 2px;
  left: 3px;
}
.HotTubPositionsMenu-middleTopWater.opalMiddleTopWater:after {
  background-image: url("../../assets/images/positionIcons/opalIcons/opal-1.png");
  top: 6px;
  left: 6px;
}
.HotTubPositionsMenu-middle:after {
  background-image: url("../../assets/images/positionIcons/hot-tub-position-middle.svg");
  top: -3px;
  left: 6px;
}
.HotTubPositionsMenu-middle.jadeMiddle:after {
  background-image: url("../../assets/images/positionIcons/jadeIcons/jade-2.png");
  top: 5px;
  left: 4px;
}
.HotTubPositionsMenu-middle.opalMiddle:after {
  background-image: url("../../assets/images/positionIcons/opalIcons/opal-2.png");
  top: 0;
  left: 5px;
}
.HotTubPositionsMenu-middleTop:after {
  background-image: url("../../assets/images/positionIcons/hot-tub-position-middle-top.svg");
  top: 4px;
}
.HotTubPositionsMenu-middleTop.jadeMiddleTop:after {
  background-image: url("../../assets/images/positionIcons/jadeIcons/jade-3.png");
  top: 2px;
  left: 5px;
}
.HotTubPositionsMenu-middleTop.opalMiddleTop:after {
  background-image: url("../../assets/images/positionIcons/opalIcons/opal-3.png");
  top: 2px;
  left: 5px;
}
.HotTubPositionsMenu-top:after {
  width: 34px!important;
  height: 34px!important;
  background-image: url("../../assets/images/positionIcons/hot-tub-position-top.svg");
}
.HotTubPositionsMenu-top.jadeTop:after {
  background-image: url("../../assets/images/positionIcons/jadeIcons/jade-top-alternative.png");
  left: 8px;
  top: 8px;
}
@media screen and (max-device-width: 770px) {
  .HotTubPositionsMenu-top.jadeTop:after {
    width: 30px!important;
    height: 30px!important;
    left: 5px;
    top: 5px;
  }
}
.HotTubPositionsMenu-top.opalTop:after {
  background-image: url("../../assets/images/positionIcons/opalIcons/opal-top-alternative.png");
  left: 8px;
  top: 8px;
}
@media screen and (max-device-width: 770px) {
  .HotTubPositionsMenu-top.opalTop:after {
    width: 30px!important;
    height: 30px!important;
    left: 5px;
    top: 4px;
  }
}
