.ToggleSwitchButton {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 17px;
}
@media screen and (max-device-width: 330px) {
  .ToggleSwitchButton {
    width: 20px;
    height: 10px;
  }
}
.ToggleSwitchButton > input {
  opacity: 0;
  width: 0;
  height: 0;
}
.ToggleSwitchButton > input:checked + .ToggleSwitchButton-slider {
  background-color: #EAEAEA;
}
.ToggleSwitchButton > input:checked + .ToggleSwitchButton-slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
  background-color: #774D2E;
  border: 0.5px solid #D0D0D0;
}
@media screen and (max-device-width: 330px) {
  .ToggleSwitchButton > input:checked + .ToggleSwitchButton-slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }
}
.ToggleSwitchButton > input:focus + .ToggleSwitchButton-slider {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.25);
}
.ToggleSwitchButton-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #EAEAEA;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 0.5px solid #D0D0D0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}
.ToggleSwitchButton-slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: -1px;
  bottom: -2px;
  background: #444444;
  border: none;
  box-sizing: border-box;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
@media screen and (max-device-width: 330px) {
  .ToggleSwitchButton-slider:before {
    height: 12px;
    width: 12px;
  }
}
