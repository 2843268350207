.CartPaymentsMethod {
  width: 42%;
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
@media screen and (max-width: 1000px) {
  .CartPaymentsMethod {
    width: 100%;
    margin: 0;
    padding: 0 15px;
  }
}
.CartPaymentsMethod-box {
  padding: 15px 30px 30px;
  border: 2px solid #a27557;
}
@media screen and (max-width: 1000px) {
  .CartPaymentsMethod-box {
    padding: 15px;
  }
}
.CartPaymentsMethod-box-button {
  margin: 10px 0;
  outline: none;
  color: #fff;
  background-color: #777;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  cursor: pointer;
  font-weight: bolder;
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  vertical-align: middle;
  border-radius: 0;
  text-shadow: none;
  line-height: 2.4em;
  min-height: 2.5em;
  padding: 0 1.2em;
  min-width: 135px;
  max-width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CartPaymentsMethod-box-button.loader .Loader {
  width: 20px;
  height: 20px;
  border-color: #ffffff;
}
.CartPaymentsMethod-box-button:hover {
  background-color: #525252;
}
.CartPaymentsMethod-box-button:active {
  position: relative;
  top: 2px;
}
.CartPaymentsMethod-order {
  position: relative;
}
.CartPaymentsMethod-order-loader {
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffffb3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CartPaymentsMethod-order-agreement > p {
  font-size: 1.1em;
  overflow: hidden;
  padding-top: 10px;
  font-family: "Montserrat_Bold", sans-serif;
  font-weight: bolder;
  text-transform: uppercase;
  color: #555;
  margin-top: 0;
  margin-bottom: 0.7rem;
  text-rendering: optimizeSpeed;
}
.CartPaymentsMethod-order-agreement-label {
  font-family: "Montserrat_400", sans-serif;
  font-size: 0.9em;
  line-height: 1.6;
}
.CartPaymentsMethod-order-agreement-label > a {
  color: #334862;
  text-decoration: none;
}
.CartPaymentsMethod-order-agreement-label > a:hover {
  color: #000;
}
.CartPaymentsMethod-order-agreement-label > a:focus {
  outline: none;
}
