.CartOrderSuccess {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  box-sizing: border-box;
}
.CartOrderSuccess-paymentInfo {
  width: 100%;
  max-width: 1080px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 50px;
}
@media (max-width: 1100px) {
  .CartOrderSuccess-paymentInfo {
    flex-direction: column;
    align-items: flex-start;
  }
}
.CartOrderSuccess-paymentInfo ul {
  list-style: disc;
  margin-bottom: 1.3em;
}
.CartOrderSuccess-paymentInfo ul > li {
  font-family: "Montserrat_400";
  font-size: 0.9rem;
  margin-bottom: 0.6rem;
  color: #777777;
}
.CartOrderSuccess-paymentInfo ul > li ::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0 !important;
  text-align: start !important;
  text-align-last: start !important;
}
.CartOrderSuccess-paymentInfo-bank {
  width: 60%;
}
@media (max-width: 1100px) {
  .CartOrderSuccess-paymentInfo-bank {
    width: 100%;
  }
}
.CartOrderSuccess-paymentInfo-bank-title {
  font-size: 1.6rem;
  color: #555;
  font-family: "Montserrat_400";
  margin-bottom: 15px;
}
.CartOrderSuccess-paymentInfo-bank-secTitle {
  font-size: 1.25rem;
  color: #555;
  font-family: "Montserrat_400";
  margin-bottom: 15px;
}
.CartOrderSuccess-paymentInfo-bank-list {
  padding: 0 0 0 20px;
}
.CartOrderSuccess-paymentInfo-bank-products {
  width: 100%;
  margin-bottom: 20px;
}
.CartOrderSuccess-paymentInfo-bank-products-container {
  width: 100%;
  height: max-content;
  background-color: #eeeeee;
  padding: 5px;
  box-sizing: border-box;
}
.CartOrderSuccess-paymentInfo-bank-products-container-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
  border-bottom: 3px solid #ececec;
}
.CartOrderSuccess-paymentInfo-bank-products-container-header > p {
  color: #777777;
  font-family: "Montserrat_Bold";
  font-size: 0.9rem;
}
.CartOrderSuccess-paymentInfo-bank-products-container-products {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.CartOrderSuccess-paymentInfo-bank-products-container-products > p {
  width: 20%;
}
.CartOrderSuccess-paymentInfo-bank-products-container-products-product {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.CartOrderSuccess-paymentInfo-bank-products-container-products-product > p {
  color: #666;
  font-family: "Montserrat_400";
  font-size: 0.9rem;
}
.CartOrderSuccess-paymentInfo-bank-products-container-summary {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.CartOrderSuccess-paymentInfo-bank-products-container-summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ececec;
}
.CartOrderSuccess-paymentInfo-bank-products-container-summary-item-title {
  color: #777;
  font-family: "Montserrat_400";
  font-size: 0.8rem;
  font-weight: 600;
}
.CartOrderSuccess-paymentInfo-bank-products-container-summary-item-price {
  text-align: end;
  color: #111;
  font-family: "Montserrat_Bold";
  font-size: 0.8rem;
  white-space: nowrap;
}
.CartOrderSuccess-paymentInfo-bank-delivery {
  display: flex;
  flex-direction: column;
}
.CartOrderSuccess-paymentInfo-bank-delivery > span {
  margin-bottom: 8px;
}
.CartOrderSuccess-paymentInfo-order {
  width: 40%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.02);
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.07), inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  padding: 30px 30px 0 30px;
}
@media (max-width: 1100px) {
  .CartOrderSuccess-paymentInfo-order {
    width: 100%;
  }
}
.CartOrderSuccess-paymentInfo-order > p {
  font-family: "Montserrat_400";
  color: #7a9c59;
  margin-bottom: 1.3rem;
}
