.ColorsOption {
  padding-bottom: 15px;
}
.ColorsOption-collapse {
  transition: height 0.7s ease;
}
.ColorsOption-content {
  height: max-content;
  background-color: #fff;
  position: relative;
}
.ColorsOption-moreInfo {
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
  margin: 10px 0 10px 0;
  cursor: pointer;
}
.ColorsOption-moreInfo > img {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 7px;
}
.ColorsOption-moreInfo > p {
  font-family: 'Montserrat_400';
  font-size: 1rem;
  color: #774D2E;
}
.ColorsOption-moreInfo-tooltip {
  z-index: 10000 !important;
  background-color: #FFFFFF !important;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1) !important;
  opacity: 1 !important;
  margin-left: -45px !important;
}
.ColorsOption-moreInfo-tooltip-box {
  width: 340px;
  height: max-content;
}
.ColorsOption-moreInfo-tooltip-box.collapse {
  width: 100%;
}
.ColorsOption-moreInfo-tooltip-box-gotIt {
  font-family: 'Montserrat_400';
  font-size: 1rem;
  line-height: 19px;
  text-align: right;
  color: #774D2E;
  margin-bottom: 10px;
}
.ColorsOption-moreInfo-tooltip-box-title {
  font-family: 'Montserrat_300';
  font-size: 1.9rem;
  color: #0E0E0E;
  margin-bottom: 10px;
}
.ColorsOption-moreInfo-tooltip-box-desc {
  margin-bottom: 20px;
}
.ColorsOption-moreInfo-tooltip-box-desc.collapse-desc {
  margin: 0;
}
.ColorsOption-moreInfo-tooltip-box-desc > p {
  font-family: 'Montserrat_300';
  font-size: 1rem;
  color: #444444;
}
.ColorsOption-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  margin-bottom: 15px;
}
.ColorsOption-box-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  justify-content: space-between;
  border: 1.5px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  padding: 15px 3px;
  position: relative;
}
.ColorsOption-box-item.delivery {
  justify-content: center !important;
  margin-bottom: 5px;
}
.ColorsOption-box-item.delivery .ColorsOption-box-item-price {
  margin-bottom: 5px;
}
.ColorsOption-box-item.selected {
  border: 1.5px solid #774D2E;
}
.ColorsOption-box-item.selected:after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("../../assets/images/check-selected-icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 10px;
  right: 10px;
}
.ColorsOption-box-item.selected.without-image:after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("../../assets/images/check-selected-icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: initial;
  bottom: 5px;
  right: 5px;
}
.ColorsOption-box-item.multiSelected {
  border: 1.5px solid #774D2E;
}
.ColorsOption-box-item.multiSelected:after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("../../assets/images/check-selected-icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: initial;
  bottom: 5px;
  right: 5px;
}
.ColorsOption-box-item.without-image {
  text-align: center;
  padding: 15px 5px;
  justify-content: space-between;
}
.ColorsOption-box-item > img {
  width: 42px;
  height: 42px;
  margin-bottom: 6px;
  border-radius: 50%;
}
.ColorsOption-box-item-name {
  font-family: 'Montserrat_300';
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 4px;
  color: #444444;
  text-align: center;
}
.ColorsOption-box-item-price {
  font-family: 'Montserrat_400';
  font-size: 0.625rem;
  line-height: 13px;
  color: #444444;
  text-align: center;
}
.ColorsOption-box-item-canBuyFew {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.ColorsOption-box-item-canBuyFew .disabled {
  background-color: rgba(224, 224, 224, 0.59);
}
.ColorsOption-box-item-canBuyFew > span {
  font-size: 1rem;
  margin: 0 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ada3a396;
  cursor: pointer;
  line-height: 1.4;
}
.ColorsOption-box-item-canBuyFew > p {
  font-family: 'Montserrat_400';
  font-size: 1rem;
  line-height: 1;
}
