.TotalAmountCard {
  width: 350px;
  min-height: 175px;
  height: max-content;
  max-height: 210px;
  /*height: 175px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F8FBFF;
  border-radius: 4px;
  padding: 15px 20px 30px;
  box-sizing: border-box;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 0;
  bottom: 0;
}
@media screen and (max-device-width: 770px) {
  .TotalAmountCard {
    display: none;
    background-color: transparent;
    box-shadow: none;
  }
}
@media screen and (max-device-width: 380px) {
  .TotalAmountCard {
    padding: 15px 10px 30px;
  }
}
@media screen and (max-device-width: 330px) {
  .TotalAmountCard {
    padding: 15px 5px 30px;
  }
}
.TotalAmountCard > p {
  width: 100%;
  font-family: "Montserrat_400";
  font-size: 0.75rem;
  color: #774D2E;
  text-align: end;
  margin-bottom: 15px;
}
.TotalAmountCard-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.TotalAmountCard-title-amount {
  font-family: "Montserrat_300";
  font-size: 1rem;
  color: #0E0E0E;
}
.TotalAmountCard-title-price {
  font-family: "Spartan_500";
  font-size: 1.5rem;
  color: #0E0E0E;
  margin-right: 5px;
}
.TotalAmountCard > button {
  position: relative;
  top: 0;
  width: 100%;
  height: 39px;
  border: none;
  font-size: 1rem;
  font-family: "Montserrat_400";
  color: #fff;
  padding: 10px;
  background-color: #774D2E;
  border-radius: 90px;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 10px;
  line-height: 1!important;
  transition: top 0.3s linear;
}
.TotalAmountCard > button:active {
  position: relative;
  top: 2px;
  transition: top 0.3s linear;
}
.TotalAmountCard-share {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.TotalAmountCard-share-button {
  margin-right: 5px;
  margin-bottom: 0!important;
}
.TotalAmountCard-share > p {
  font-family: "Montserrat_400" !important;
  font-size: 0.875rem !important;
  color: #774D2E !important;
  margin: 0 10px 0 0;
  text-align: start!important;
}
.TotalAmountCard-pdfTextDownload {
  font-family: "Montserrat_400" !important;
  font-size: 0.875rem !important;
  color: #774D2E !important;
  text-decoration: none!important;
  cursor: pointer;
  margin-bottom: 10px!important;
  text-align: center!important;
}
