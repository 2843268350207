.CartPaymentOrder {
  background-color: #eeeeee;
  padding: 0.5em;
}
.CartPaymentOrder-option-text {
  margin-top: 5px;
}
.CartPaymentOrder-header {
  display: flex;
  justify-content: space-between;
  text-align: left;
  border-bottom: 1px solid #ececec;
  line-height: 1.5;
  font-size: 0.9em;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #777;
  font-family: "Montserrat_Bold", sans-serif;
  padding: 0.5em 0;
}
.CartPaymentOrder-product {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0;
}
.CartPaymentOrder-product-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-top: 5px;
}
.CartPaymentOrder-product-icon > img {
  width: 100%;
  height: 100%;
}
.CartPaymentOrder-product-option {
  font-family: "Montserrat_400", sans-serif;
  font-size: 0.9em;
  color: #666;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.CartPaymentOrder-product-option-text {
  justify-self: start;
}
.CartPaymentOrder-product-option-name {
  display: inline-block;
  margin-right: 0.3em;
}
.CartPaymentOrder-product-option-amount {
  font-family: "Montserrat_Bold", sans-serif;
  display: inline-block;
  margin-left: 0.3em;
}
.CartPaymentOrder-product-option-desc {
  margin-top: 0.2em;
}
.CartPaymentOrder-product-price {
  font-family: "Montserrat_400", sans-serif;
  font-size: 0.8em;
  color: #666;
  min-width: 7em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.CartPaymentOrder-totalSum {
  display: flex;
  /*flex-direction: row;*/
  justify-content: flex-end;
  align-items: center;
  font-family: "Montserrat_Bold", sans-serif;
  font-size: 0.8em;
  color: #777;
  line-height: 1.5;
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
}
.CartPaymentOrder-totalSum > p:last-child {
  border: 1px solid #fff;
  border-bottom: transparent;
  padding: 10px 10px;
  color: #111;
}
.CartPaymentOrder-transportation {
  padding-top: 0.5em;
}
.CartPaymentOrder-transportation > p {
  font-family: "Montserrat_Bold";
  padding: 0.5em;
  font-size: 0.77rem;
  color: #666;
}
.CartPaymentOrder-transportation-method {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: max-content;
  padding: 0.5em;
  border-bottom: 1px solid #ececec;
  box-sizing: border-box;
}
.CartPaymentOrder-transportation-method > p {
  font-family: "Montserrat_400";
  padding: 0.5em;
  font-size: 0.77rem;
  cursor: pointer;
  color: #334862;
}
.CartPaymentOrder-transportation-method > p:hover {
  color: #000;
}
.CartPaymentOrder-transportation-method-radio {
  font-family: "Montserrat_400";
  font-size: 0.77rem;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.01);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.3em 0.6em;
  border-radius: 5px;
  margin-bottom: 5px;
  min-height: 2.4em;
  cursor: pointer;
}
.CartPaymentOrder-transportation-method-radio > b {
  font-family: "Montserrat_Bold";
  margin-left: 5px;
}
.CartPaymentOrder-transportation-method-radio.checked {
  font-family: "Montserrat_Bold";
}
.CartPaymentOrder-transportation-method-radio > input {
  margin: 0 10px 0 0;
}
.CartPaymentOrder-title,
.CartPaymentOrder-common,
.CartPaymentOrder-VATIncluded {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
}
.CartPaymentOrder-title-name,
.CartPaymentOrder-common-name,
.CartPaymentOrder-VATIncluded-name {
  font-family: "Montserrat_Bold";
  padding-top: 0.5em;
  font-size: 0.77rem;
  color: #666;
}
.CartPaymentOrder-title-price,
.CartPaymentOrder-common-price,
.CartPaymentOrder-VATIncluded-price {
  font-family: "Montserrat_Bold";
  padding: 0.5em;
  font-size: 0.77rem;
}
.CartPaymentOrder-common {
  border-bottom: 3px solid #ececec;
}
.CartPaymentOrderWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
