.CartPaymentOptions {
  position: relative;
}
.CartPaymentOptions-loader {
  z-index: 10;
  position: absolute;
  width: calc(100% - 40px);
  height: 100%;
  background-color: #ffffffb3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CartPaymentOptions > p {
  font-size: 1.1em;
  overflow: hidden;
  padding-top: 10px;
  font-family: "Montserrat_Bold", sans-serif;
  font-weight: bolder;
  text-transform: uppercase;
  color: #555;
  margin-top: 0;
  text-rendering: optimizeSpeed;
}
.CartPaymentOptions-method {
  border-bottom: 1px solid #ececec;
  padding-right: 1em;
}
.CartPaymentOptions-method:last-child {
  border-bottom: none;
}
.CartPaymentOptions-method-radio {
  padding-top: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px;
  color: #222;
  font-family: "Montserrat_Bold", sans-serif;
  line-height: 1.6;
  font-size: 0.8em;
}
.CartPaymentOptions-method-radio > input {
  display: inline;
  margin: 0 10px 0 5px;
  font-size: 16px;
}
.CartPaymentOptions-method-radio > img {
  margin: 0 5px;
}
.CartPaymentOptions-method-radio > a {
  color: #334862;
  text-decoration: none;
  font-size: 0.83em;
  cursor: pointer;
}
.CartPaymentOptions-method-collapse {
  transition: height 0.7s ease;
}
.CartPaymentOptions-method-content {
  height: max-content;
  background-color: #fff;
  position: relative;
}
.CartPaymentOptions-method-content-data {
  font-family: "Montserrat_400", sans-serif;
  font-size: 0.8em;
  line-height: 1.6;
  color: #777;
  margin-bottom: 0.5em;
}
.CartPaymentOptions-method-payNow > p {
  font-family: "Klarna Text", "Klarna Sans", Helvetica, Arial, sans-serif;
  line-height: 25px;
  margin: 0 0 5px 0;
}
.CartPaymentOptions-method-payNow-title {
  font-size: 16px;
  color: #171717;
  font-weight: 700;
}
.CartPaymentOptions-method-payNow-subtitle {
  font-size: 16px;
  color: #787573;
  font-weight: 400;
}
.CartPaymentOptions-method-payNow .checkedCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  border: 3px solid #487b93;
  border-radius: 5px;
  height: 60px;
  padding: 9px 14px;
  width: 100%;
}
.CartPaymentOptions-method-payNow .noCheckedCard {
  border: 3px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: 5px;
  height: 60px;
  padding: 9px 14px;
  width: 100%;
}
.CartPaymentOptions-method-payNow-radio {
  display: flex;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid #969391;
  font-family: "Klarna Text", "Klarna Sans", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
}
.CartPaymentOptions-method-payNow-radio:nth-child(3) {
  border-radius: 5px 5px 0 0;
  border-bottom-color: transparent;
}
.CartPaymentOptions-method-payNow-radio:nth-child(5) {
  border-radius: 0 0 5px 5px;
  border-top-color: transparent;
  margin-bottom: 1em;
}
.CartPaymentOptions-method-payNow-radio > input {
  display: none;
}
.CartPaymentOptions-method-payNow-collapse {
  transition: height 0.1s none;
}
.CartPaymentOptions-method-payNow-content {
  height: max-content;
  background-color: #fff;
  position: relative;
}
.CartPaymentOptions-method-payNow-content-images {
  margin-top: 10px;
}
.CartPaymentOptions-method-payNow-content-images > img {
  margin-right: 4px;
}
.CartPaymentOptions-method-payNow-content-list {
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-shrink: 0;
  border-style: solid;
  border-width: 0px;
  position: relative;
  z-index: 0;
  min-height: 0px;
  min-width: 0px;
  padding: 15px;
  background-color: #f0eeeb;
  border-radius: 0px;
  margin: 10px 0;
}
.CartPaymentOptions-method-payNow-content-list > ul {
  margin: 0 0 0 20px;
  padding: 0;
}
.CartPaymentOptions-method-payNow-content-list > ul > li {
  max-width: 100%;
  font-family: "Klarna Text", "Klarna Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color: #171717;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricprecision;
  text-size-adjust: none;
  margin-bottom: 10px;
}
.CartPaymentOptions-method-payNow-content-list > ul > li > a {
  color: #171717;
  cursor: pointer;
  text-decoration: underline #171717;
}
.CartPaymentOptions-method-payNow-condition {
  max-width: 100%;
  color: #787573;
  font-family: "Klarna Text", "Klarna Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4;
  text-align: left;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 4px;
  padding-top: 1px;
}
.CartPaymentOptions-method-payNow-condition > a {
  color: #171717;
  cursor: pointer;
  text-decoration: underline #171717;
}
.CartPaymentOptions-method-payNow-condition-modal {
  background-color: #777;
}
.CartPaymentOptions-method-payNow-condition-modal-container {
  background-color: #fff;
  height: 100%;
  width: 50%;
  position: relative;
}
@media screen and (max-width: 1400px) {
  .CartPaymentOptions-method-payNow-condition-modal-container {
    width: 70%;
  }
}
@media screen and (max-width: 1000px) {
  .CartPaymentOptions-method-payNow-condition-modal-container {
    width: 85%;
  }
}
@media screen and (max-width: 820px) {
  .CartPaymentOptions-method-payNow-condition-modal-container {
    width: 95%;
  }
}
@media screen and (max-width: 735px) {
  .CartPaymentOptions-method-payNow-condition-modal-container {
    width: 100%;
  }
}
.CartPaymentOptions-method-payNow-condition-close {
  opacity: 1;
  height: 20px;
}
.CartPaymentOptions-method-payNow-condition-close::before,
.CartPaymentOptions-method-payNow-condition-close::after {
  height: 20px;
}
.CartPaymentOptions-method-payNow-condition-frame {
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  border-width: 0px;
  position: relative;
  z-index: 0;
  background-color: #fff;
  height: 100%;
  width: 100%;
  padding-top: 15px;
}
