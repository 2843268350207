.CartDataSubtotal {
  width: 35%;
  height: max-content;
  position: sticky;
  top: 10px;
  padding: 0 20px;
  box-sizing: border-box;
}
@media (max-width: 560px) {
  .CartDataSubtotal {
    padding: 0;
  }
}
@media (max-width: 1000px) {
  .CartDataSubtotal {
    position: static;
    width: 100%;
    margin-bottom: 20px;
  }
}
.CartDataSubtotal-loader {
  z-index: 10;
  position: absolute;
  width: calc(100% - 40px);
  height: 100%;
  background-color: #ffffffb3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CartDataSubtotal-header {
  border-bottom: 3px solid #ececec;
  margin-bottom: 20px;
}
.CartDataSubtotal-header > p {
  font-family: "Montserrat_Bold";
  font-size: 0.9rem;
  color: #777;
  padding: 0.5em;
}
.CartDataSubtotal-title,
.CartDataSubtotal-common,
.CartDataSubtotal-VATIncluded {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
}
.CartDataSubtotal-title-name,
.CartDataSubtotal-common-name,
.CartDataSubtotal-VATIncluded-name,
.CartDataSubtotal-title-price,
.CartDataSubtotal-common-price,
.CartDataSubtotal-VATIncluded-price {
  font-family: "Montserrat_400";
  padding: 0.5em;
  font-size: 0.9rem;
}
.CartDataSubtotal-title-price,
.CartDataSubtotal-common-price,
.CartDataSubtotal-VATIncluded-price {
  font-family: "Montserrat_Bold";
}
.CartDataSubtotal-common {
  border-bottom: 3px solid #ececec;
}
.CartDataSubtotal-transportation-method {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: max-content;
  border-bottom: 1px solid #ececec;
  box-sizing: border-box;
}
.CartDataSubtotal-transportation-method.delivery {
  flex-direction: column;
}
.CartDataSubtotal-transportation-method-title,
.CartDataSubtotal-transportation-method-title-collapse {
  font-family: "Montserrat_400";
  padding: 0.5em;
  font-size: 0.9rem;
}
.CartDataSubtotal-transportation-method-title-collapse {
  cursor: pointer;
  color: #334862;
}
.CartDataSubtotal-transportation-method-title-collapse:hover {
  color: #000;
}
.CartDataSubtotal-transportation-method-radio {
  font-family: "Montserrat_400";
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.01);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.3em 0.6em;
  border-radius: 5px;
  margin-bottom: 5px;
  min-height: 2.4em;
  cursor: pointer;
}
.CartDataSubtotal-transportation-method-radio > b {
  font-family: "Montserrat_Bold";
  margin-left: 5px;
}
.CartDataSubtotal-transportation-method-radio.checked {
  font-family: "Montserrat_Bold";
}
.CartDataSubtotal-transportation-method-radio > input {
  margin: 0 10px 0 0;
}
.CartDataSubtotal-transportation-method-collapse {
  transition: height 0.7s ease;
}
.CartDataSubtotal-transportation-method-content {
  height: max-content;
  background-color: #fff;
  position: relative;
}
.CartDataSubtotal > button {
  width: 100%;
  margin: 20px 0;
  outline: none;
  color: #fff;
  background-color: #777;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  cursor: pointer;
  font-weight: bolder;
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  vertical-align: middle;
  border-radius: 0;
  text-shadow: none;
  line-height: 2.4em;
  min-height: 2.5em;
  padding: 0 1.2em;
  max-width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.CartDataSubtotal > button:hover {
  background-color: #525252;
}
.CartDataSubtotal > button:active {
  position: relative;
  top: 2px;
}
