.Holz {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  background: linear-gradient(180deg, #CFCFCF 0%, #CFCFCF 12.59%, #E1E1E1 60.81%, #EAEAEA 74.35%, #EAEAEA 100%);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  position: relative;
}
@media screen and (max-width: 770px) {
  .Holz {
    height: 442px;
  }
}
@media screen and (max-width: 380px) {
  .Holz {
    height: 330px;
  }
}
@media screen and (max-width: 340px) {
  .Holz {
    height: 342px;
  }
}
.Holz-bcgOpacityModal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  background-color: #000;
  z-index: 99;
  transition: all 0.3s linear;
}
.Holz-bcgOpacityModal.visible {
  visibility: visible;
  opacity: 0.7;
}
.Holz .HotTubCanvasView {
  width: 100%;
  height: 100%;
}
.Holz .Controls-totalAmount-customizeHotTub-container {
  width: 100%;
  position: relative;
  height: 100%;
}
.Holz .Controls-totalAmount-customizeHotTub-container .HotTubPositionsMenu {
  position: fixed;
  top: calc(20px + 100px);
  left: 15px;
}
@media screen and (max-device-width: 770px) {
  .Holz .Controls-totalAmount-customizeHotTub-container .HotTubPositionsMenu {
    flex-direction: row;
    top: calc(20px + 70px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
  }
}
@media screen and (max-device-width: 770px) {
  .Holz .Controls-totalAmount-customizeHotTub-container .HotTubPositionsMenu > span {
    margin: 0 15px 0 0;
  }
}
.Holz .Controls-totalAmount-customizeHotTub-container > .ControlItem {
  position: fixed;
  top: calc(20px + 100px);
  right: 370px;
  width: max-content;
}
@media screen and (max-device-width: 770px) {
  .Holz .Controls-totalAmount-customizeHotTub-container > .ControlItem {
    display: none;
  }
}
.Holz .Controls-totalAmount-customizeHotTub-container .HotTubCustomize {
  margin: 107px 5px 0 0;
}
@media screen and (max-device-width: 770px) {
  .Holz .Controls-totalAmount-customizeHotTub-container .HotTubCustomize {
    margin: 0;
  }
}
.Holz .Controls-totalAmount-customizeHotTub-container .TotalAmountCard {
  margin: 0 5px 7px 0;
}
