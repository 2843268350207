.CartModal {
  position: fixed;
  /*width: 97%;
    height: 95%;*/
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  visibility: hidden;
  opacity: 0;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  z-index: 100;
  transition: all 0.3s linear;
  box-sizing: border-box;
  /*@media (max-width: 1100px){
        width: 100%;
        height: 100%;
    }*/
}
.CartModal::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
}
.CartModal.visible {
  visibility: visible;
  opacity: 1;
}
.CartModal-container {
  width: 75%;
  padding: 30px 15px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  /*border: 1px solid red;*/
  box-sizing: border-box;
}
@media (max-width: 1400px) {
  .CartModal-container {
    width: 90%;
  }
}
@media (max-width: 1100px) {
  .CartModal-container {
    width: 100%;
  }
}
