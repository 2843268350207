.CartOrderCustomerDataForm {
  width: 100%;
  display: grid;
  grid-gap: 20px;
}
@media screen and (max-width: 700px) {
  .CartOrderCustomerDataForm {
    width: 100%;
    margin: 0 0 20px;
    padding: 0 10px 0 0;
  }
}
.CartOrderCustomerDataForm-line {
  border-top: 2px solid #ddd;
}
.CartOrderCustomerDataForm > p {
  font-size: 1em;
  overflow: hidden;
  padding-top: 10px;
  font-weight: bolder;
  text-transform: uppercase;
  color: #555;
  width: 100%;
  margin-top: 0;
  text-rendering: optimizeSpeed;
  font-family: "Montserrat_Bold";
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
}
.CartOrderCustomerDataForm-firstBlock,
.CartOrderCustomerDataForm-fourthBlock {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  align-items: flex-end;
}
@media screen and (max-width: 450px) {
  .CartOrderCustomerDataForm-firstBlock,
  .CartOrderCustomerDataForm-fourthBlock {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
}
.CartOrderCustomerDataForm-firstBlock-box,
.CartOrderCustomerDataForm-fourthBlock-box {
  width: 100%;
  position: relative;
}
.CartOrderCustomerDataForm-box {
  width: fit-content;
  position: relative;
}
.CartOrderCustomerDataForm-collapse {
  transition: height 0.7s;
}
.CartOrderCustomerDataForm-content {
  margin-top: 20px;
  display: grid;
  grid-gap: 20px;
  background-color: #fff;
  position: relative;
}
