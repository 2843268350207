.TypeOption {
  border-bottom: 1px solid #EAEAEA;
}
.TypeOption > p {
  font-family: "Montserrat_300";
  font-size: 1.7rem;
  line-height: 38px;
  color: #0E0E0E;
  margin-bottom: 10px;
}
@media (max-width: 380px) {
  .TypeOption > p {
    line-height: 1;
    font-size: 1.5rem;
  }
}
.TypeOption-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  border: 1px solid #EAEAEA;
  margin-bottom: 10px;
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  /*> img {
      width: 60px;
      height: 60px;
      margin: 0 20px;
    }*/
}
@media (max-width: 380px) {
  .TypeOption-item {
    height: 75px;
  }
}
.TypeOption-item-image {
  width: 60px;
  height: 60px;
  margin: 0 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.TypeOption-item-image.saphirIcon {
  background-image: url("../../assets/images/saphir-icon.png");
}
.TypeOption-item-image.jadeIcon {
  background-image: url("../../assets/images/jade-icon.png");
}
.TypeOption-item-image.opalIcon {
  background-image: url("../../assets/images/opal-icon.png");
}
.TypeOption-item.selected {
  border: 1.5px solid #774D2E;
  pointer-events: none;
}
.TypeOption-item.selected:after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("../../assets/images/check-selected-icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.TypeOption-item > p {
  font-family: "Montserrat_400";
  font-size: 1.125rem;
  color: #0E0E0E;
  line-height: 1;
}
.TypeOption-item-typeName {
  position: relative;
}
.TypeOption-item-typeName:before {
  content: '';
  display: block;
  font-size: 2rem;
  position: absolute;
  left: -40px;
  top: 50%;
  line-height: 1;
  transform: translateY(-50%);
  color: rgba(180, 180, 180, 0.71);
}
