.Loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px dashed #777;
  animation: rotate 2s infinite linear;
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
