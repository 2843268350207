.Stepper {
  width: 100%;
}
.Stepper-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 40px;
}
@media screen and (max-width: 600px) {
  .Stepper-header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.Stepper-header-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.Stepper-header-item > p {
  font-family: "Montserrat_400", sans-serif;
  font-size: 1.2em;
  line-height: 1.6;
  color: #ccc;
  user-select: none;
}
.Stepper-header-item::after {
  display: inline-block;
  content: ">";
  padding: 0 0.5em;
  color: #ccc;
}
@media screen and (max-width: 600px) {
  .Stepper-header-item::after {
    display: none;
  }
}
.Stepper-header-item:last-child::after {
  display: none;
}
.Stepper-header-item.completed > p {
  color: #111;
}
@media screen and (max-width: 600px) {
  .Stepper-header-item.noCompleted {
    display: none;
  }
}
