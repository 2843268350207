.Popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  overflow-y: auto;
  background-color: #fff;
  z-index: 100;
  transition: all 0.3s linear;
  box-sizing: border-box;
  pointer-events: none;
}
.Popup::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
}
.Popup.visible {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
.Popup-container {
  width: 75%;
  padding: 30px 15px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
@media (max-width: 1400px) {
  .Popup-container {
    width: 90%;
  }
}
@media (max-width: 1100px) {
  .Popup-container {
    width: 100%;
  }
}
