.HotTubControls {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  width: max-content;
  height: 60px;
  position: fixed;
  top: calc(7px + 100px);
  right: 360px;
  border-radius: 20px;
}
@media screen and (max-width: 1050px) {
  .HotTubControls {
    grid-template-columns: repeat(2, 1fr);
    height: 80px;
    top: initial;
    right: initial;
    bottom: 20px;
    left: 20px;
  }
}
@media screen and (max-device-width: 770px) {
  .HotTubControls {
    grid-template-columns: repeat(4, 1fr);
    width: 90%;
    height: 45px;
    top: calc(130px + 312px - 50px);
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-device-width: 380px) {
  .HotTubControls {
    height: 40px;
    top: calc(130px + 312px - 155px);
  }
}
@media screen and (max-device-width: 330px) {
  .HotTubControls {
    width: 95%;
    top: calc(130px + 342px - 165px);
    height: 30px;
  }
}
