.ControlItem {
  width: 137px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
}
@media screen and (max-device-width: 440px) {
  .ControlItem {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: initial;
  }
}
.ControlItem_image {
  width: 20px;
  height: 20px;
  background-image: url("../../../assets/images/positionIcons/full-screen-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.ControlItem > p {
  font-family: "Montserrat_Bold";
  font-size: 0.875rem;
  line-height: 17px;
  color: #000;
  text-transform: uppercase;
}
@media screen and (max-device-width: 440px) {
  .ControlItem > p {
    font-size: 0.575rem;
    line-height: 1;
  }
}
