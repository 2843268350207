.TextField {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.TextField-header {
  font-weight: bold;
  display: block;
  font-size: 0.8em;
  margin-bottom: 0.4em;
  color: #222;
  font-family: "Montserrat_Bold";
}
.TextField-header.obligatory:after {
  content: " * ";
}
.TextField-input {
  box-sizing: border-box;
  border: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0 0.75em;
  height: 2.5em;
  border-radius: 0;
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  background-color: #fff;
  color: #333;
  font-family: "Montserrat_400";
  font-size: 0.9rem;
  margin-bottom: 0.5em;
}
.TextField-input:hover {
  box-shadow: inset 0 -1.8em 1em 0 rgba(0, 0, 0, 0);
}
.TextField-input:focus {
  box-shadow: 0 0 5px #ccc;
}
.TextField-input-error {
  border-color: #b20000;
}
