.HotTubCustomize {
  width: 350px;
  height: calc(100vh - 187px - 100px);
  /*height: calc(940px - 187px);*/
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-width: none;
  position: fixed;
  top: 0;
  right: 0;
  transition: right 0.3s linear;
}
@media screen and (max-device-width: 770px) {
  .HotTubCustomize {
    width: 100%;
    height: calc(100vh - 312px - 120px);
    top: calc(312px + 130px);
  }
}
@media screen and (max-device-width: 380px) {
  .HotTubCustomize {
    padding: 10px 10px 0 10px;
    width: 100%;
    height: calc(100vh - 312px - 18px);
    top: calc(312px + 18px);
  }
}
@media screen and (max-device-width: 330px) {
  .HotTubCustomize {
    width: 100%;
    height: calc(100vh - 300px);
    top: 340px;
    padding: 15px;
  }
}
.HotTubCustomize > .TotalAmountCard {
  display: none;
}
@media screen and (max-device-width: 770px) {
  .HotTubCustomize > .TotalAmountCard {
    display: block;
    position: initial;
    margin-bottom: 120px!important;
  }
}
.HotTubCustomize::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
}
.HotTubCustomize.closed {
  right: -355px !important;
  transition: right 0.3s linear;
}
.HotTubCustomize-title {
  width: 95%;
  font-family: "Montserrat_Bold";
  font-size: 2rem;
  line-height: 38px;
  color: #000000;
  margin-bottom: 20px;
}
.HotTubCustomize > div {
  width: 100%;
  margin-bottom: 30px;
}
@media (max-width: 380px) {
  .HotTubCustomize > div {
    margin-bottom: 20px;
  }
}
