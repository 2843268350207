.CartOrderOverview {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  box-sizing: border-box;
  position: relative;
}
@media (max-width: 1000px) {
  .CartOrderOverview {
    flex-direction: column;
  }
}
.CartOrderOverview-loader {
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffffb3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CartOrderOverview-formik {
  box-sizing: border-box;
  width: 58%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-self: flex-start;
}
@media (max-width: 1000px) {
  .CartOrderOverview-formik {
    width: 100%;
    padding-bottom: 30px;
  }
}
