.CheckIcon {
  display: inline-block;
  width: 30px;
  height: 30px;
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}
.CheckIcon-stem {
  position: absolute;
  width: 3px;
  height: 15px;
  background-color: #7a9c59;
  left: 20px;
  top: 0;
}
.CheckIcon-kick {
  position: absolute;
  width: 15px;
  height: 3px;
  background-color: #7a9c59;
  left: 8px;
  top: 12px;
}
